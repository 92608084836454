import * as React from 'react';
import { Box, GDSStyleObject } from '@leagueplatform/genesis-core';
import { AppNavBarComponent } from 'components/header-nav/nav.component';

type BasePageProps = React.PropsWithChildren<{
  css?: GDSStyleObject;
  isPrivatePage?: boolean;
}>;

export const BasePage: React.FC<BasePageProps> = ({
  children,
  isPrivatePage = true,
  css,
}) => (
  <>
    {isPrivatePage && <AppNavBarComponent />}
    <Box
      as="main"
      css={{ position: 'relative', flex: '1', overflowX: 'hidden', ...css }}
    >
      {children}
    </Box>
  </>
);
