import React from 'react';
import { useSelector } from '../hooks/useSelector';

export const ErrorView = () => {
  const errorMessage = useSelector(
    (snapshot) => (snapshot.context.error as Error).message,
  );

  return (
    <h2>
      Error!
      {errorMessage}
    </h2>
  );
};
