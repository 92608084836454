import { getModulePath, LEAGUE_MODULE_NAMES } from '@leagueplatform/core';
import { type GDSIconProps } from '@leagueplatform/genesis-core';
import { ROUTE_PATHS } from 'common/constants';
import type { LocalesKey } from 'common/types/types';

export const NAV_IDS: { [key: string]: LocalesKey } = {
  HOME: 'STR_HOME',
  BENEFITS: 'STR_BENEFITS',
  CARE: 'STR_GET_CARE',
  JOURNEY: 'STR_JOURNEY',
  ABOUT_ME: 'STR_ABOUT_ME',
};

type Keys = keyof typeof NAV_IDS;
type NavIds = (typeof NAV_IDS)[Keys];

export enum LinkType {
  NAV = 'navLink',
  LINK = 'link',
}

type NavItem = {
  to:
    | string
    | {
        search: string;
        pathname: string;
        state: unknown;
        hash: string;
        key?: string | undefined;
      };
};

export interface NavLink extends NavItem {
  message_id: NavIds;
  type: LinkType;
  icon: GDSIconProps['icon'];
}

export const useNavLinksList = () => {
  const navLinks: NavLink[] = [
    {
      to: ROUTE_PATHS.HOME,
      message_id: NAV_IDS.HOME,
      type: LinkType.NAV,
      icon: 'illustrativeHome',
    },
    {
      to: getModulePath(LEAGUE_MODULE_NAMES.wallet),
      message_id: NAV_IDS.BENEFITS,
      type: LinkType.NAV,
      icon: 'capabilityWallet',
    },
    {
      to: getModulePath(LEAGUE_MODULE_NAMES.careDiscovery),
      message_id: NAV_IDS.CARE,
      type: LinkType.NAV,
      icon: 'illustrativeHeart',
    },
    {
      to: getModulePath(LEAGUE_MODULE_NAMES.healthJourney),
      message_id: NAV_IDS.JOURNEY,
      type: LinkType.NAV,
      icon: 'capabilityJourney',
    },
    {
      to: getModulePath(LEAGUE_MODULE_NAMES.aboutMe),
      message_id: NAV_IDS.ABOUT_ME,
      type: LinkType.NAV,
      icon: 'illustrativePerson',
    },
  ];

  return {
    navLinks,
  };
};
