import { fromPromise } from 'xstate';
import { client } from '../api-client';

/**
 * Given a user ID, will POST to SCAN's `start-session` API endpoing
 * and return the enrollment ID provided.
 */
export const startSession = fromPromise<string, { userId: string }>(
  async ({ input }) => {
    const { error, data } = await client.POST('/start-session', {
      body: {
        data: {
          userId: input.userId,
        },
      },
    });

    if (error) {
      throw new Error(`Failed to start session! ${error}`);
    }

    return data.data!.enrollmentId;
  },
);

/**
 * GETs all applicable enrollment periods from SCAN's `enrollment-periods` API endpoing.
 */
export const fetchEnrollmentPeriods = fromPromise(async () => {
  const { error, data } = await client.GET('/enrollment-periods');

  if (error) {
    throw new Error(`Failed to get enrollment periods! ${error}`);
  }

  /**
   * We assume that all enrollment periods returned by the API are of the same type
   * ("id"), since the designs show that we describe that type above the year selector
   * field. Just in case, we will throw an error if NOT all enrollment periods
   * are of the same type.
   */
  const enrollmentPeriodIDs = new Set(data.data!.map(({ id }) => id));

  if (enrollmentPeriodIDs.size > 1) {
    throw new Error(
      `Received multiple enrollment periods with different types!`,
    );
  }

  return data.data!;
});
