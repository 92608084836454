import React from 'react';
import { useSelector } from '../hooks/useSelector';
import { InitializingView } from '../views/initializing';
import { EligibilityQuestionsView } from '../views/eligibility-questions';
import { UpdateAddressView } from '../views/update-address';
import { ErrorView } from '../views/error';

/**
 * Listens to the current state the machine is in and displays a "view" corresponding
 * to it.
 */
export const View = () => {
  const TheView = useSelector((snapshot) => {
    if (snapshot.matches('initializing')) {
      return InitializingView;
    }

    if (snapshot.matches('eligibilityQuestions')) {
      return EligibilityQuestionsView;
    }

    if (snapshot.matches('error')) {
      return ErrorView;
    }

    if (snapshot.matches('updateAddress')) {
      return UpdateAddressView;
    }

    throw new Error(
      `No view found for state ${JSON.stringify(snapshot.value)}!`,
    );
  });

  return <TheView />;
};
