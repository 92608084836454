import { useContext } from 'react';
import { ActorRefContext } from '../actor-ref-context';

/**
 * Conveniently returns a function with which to `send` events
 * to the state machine.
 */
export const useSend = () => {
  const actorRef = useContext(ActorRefContext);

  if (!actorRef) {
    throw new Error(
      'cannot call `useSend` hook ooutside the context of the PBPChange page component!',
    );
  }

  return actorRef.send;
};
