import { createContext } from 'react';
import { ActorRefFrom } from 'xstate';
import type { PBPChangeMachine } from './state-machine/pbp-change-machine';

type ActorRef = ActorRefFrom<PBPChangeMachine>;

/**
 * Provides a ref to a running actor instance of the machine.
 */
export const ActorRefContext = createContext<ActorRef | undefined>(undefined);
