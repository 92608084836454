import { useGetUserProfile } from '@leagueplatform/web-common';

export const useUserName = () => {
  const { data: { userProfile } = {} } = useGetUserProfile();

  const userFirstName =
    userProfile?.preferredFirstName ?? userProfile?.firstName ?? '';

  const userPreferredFirstName = userProfile?.preferredFirstName ?? '';

  const userLegalFullName = `${userProfile?.firstName ?? ''} ${
    userProfile?.lastName ?? ''
  }`;

  const userInitials = `${userFirstName?.[0] ?? ''}${
    userProfile?.lastName?.[0] ?? ''
  }`;

  return {
    userPreferredFirstName,
    userFirstName,
    userLegalFullName,
    userInitials,
  };
};
