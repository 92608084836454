/* eslint-disable import/no-extraneous-dependencies */
import React, { useState } from 'react';
import {
  AppBarComponent,
  AppBarItem,
  AppBarLink,
  AppBarNavLink,
} from '@leagueplatform/web-common-components';
import { useIntl } from '@leagueplatform/locales';
import { Link } from '@leagueplatform/routing';
import { EVENT_NAME, trackAnalyticsEvent } from '@leagueplatform/analytics';
import {
  queryHelpers,
  useMediaQuery,
  styled,
} from '@leagueplatform/genesis-core';
import SCANLogo from 'assets/images/scan-logo.png';
import { COMPANY_NAME } from 'common/constants';
import { UserDropdown } from 'components/header-nav/user-dropdown/user-dropdown.component';
import { MobileNav } from 'components/header-nav/mobile-nav/mobile-nav.component';
import {
  LinkType,
  NavLink,
  useNavLinksList,
} from 'hooks/use-nav-links-list/use-nav-links-list.hook';

const APP_NAV_DEFAULT_HEIGHT = 8 * 7; // 56px – 7 lines on the Genesis 8px grid

const Logo = styled('img', {
  'max-width': '74px',
  'max-height': '21.33px',
});

export const AppNavBarComponent = React.memo(() => {
  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  const isAboveLaptop = useMediaQuery(queryHelpers.up('laptop'));

  const { formatMessage } = useIntl();
  const { navLinks } = useNavLinksList();
  const sendNavBarAnalyticsEvent = (action: string) => () =>
    trackAnalyticsEvent(EVENT_NAME.NAVIGATION_TAB_CLICKED, {
      screen_name: 'Navigation Bar',
      detail: action,
    });

  const navLinkWrapper = (navLink: NavLink) => {
    switch (navLink.type) {
      case LinkType.LINK:
        return (
          <AppBarLink
            as={Link}
            to={navLink.to}
            key={navLink.message_id}
            onClick={sendNavBarAnalyticsEvent(navLink.message_id)}
            color="onSurface.text.primary"
            hoverStyle={{ color: 'interactive.action.primary' }}
            fontWeight="semibold"
          >
            {formatMessage({ id: navLink.message_id })}
          </AppBarLink>
        );
      case LinkType.NAV:
        return (
          <AppBarNavLink
            to={navLink.to}
            key={navLink.message_id}
            onClick={sendNavBarAnalyticsEvent(navLink.message_id)}
            color="onSurface.text.primary"
            hoverStyle={{ color: 'interactive.action.primary' }}
            decorativeBarColor="interactive.action.primary"
            fontWeight="semibold"
          >
            {formatMessage({ id: navLink.message_id })}
          </AppBarNavLink>
        );
      default:
        throw new Error('invalid link type');
    }
  };
  return (
    <AppBarComponent
      navHeight={APP_NAV_DEFAULT_HEIGHT}
      headerCSS={{
        backgroundColor: '$surfaceCardSecondary',
        paddingLeft: '20px',
      }}
    >
      <AppBarItem
        flexGrow={{ _: 1 }}
        justifyContent="start"
        alignItems="center"
      >
        <Logo role="presentation" alt={COMPANY_NAME} src={SCANLogo} />
      </AppBarItem>
      {isAboveLaptop ? (
        <>
          {/* Spacer */}
          <AppBarItem flexGrow={{ _: 0, laptop: 2 }} aria-hidden="true" />
          {/* App Nav Home & Capability Areas */}
          {navLinks.map((navLink) => navLinkWrapper(navLink))}

          {/* Spacer */}
          <AppBarItem flexGrow={{ _: 1, laptop: 30 }} />

          {/* App Nav User Dropdown */}
          <AppBarItem
            alignItems="center"
            css={{
              '#user_dropdown_menu .user-dropdown-item_link': {
                fontWeight: '700',
              },

              '#user_dropdown_menu .user-dropdown_divider': {
                borderBottom: 'none',
                borderColor: '#DADADA',
              },
            }}
          >
            <UserDropdown />
          </AppBarItem>
        </>
      ) : (
        // Mobile Navigation
        <MobileNav
          open={menuOpen}
          onOpenChange={() => {
            setMenuOpen((previous) => !previous);
          }}
          closeModal={() => setMenuOpen(false)}
        />
      )}
    </AppBarComponent>
  );
});
