import type { DropdownSection } from '@leagueplatform/web-common-components';
import type { LocalesKey, Link } from 'common/types/types';
import { ROUTE_PATHS } from 'common/constants';

export type AppDropdownSection = (Omit<DropdownSection[number], 'text'> &
  Link)[];

export type UserDropdownItems = {
  heading?: LocalesKey;
  items: AppDropdownSection;
};

export const userDropdownConfig: UserDropdownItems[] = [
  {
    heading: 'STR_ACCOUNT',
    items: [
      {
        text: 'STR_PERSONAL_INFO',
        to: ROUTE_PATHS.PERSONAL_INFO,
      },
      {
        // Communication Preferences
        text: 'STR_COMMUNICATION_PREFERENCES',
        to: '#',
      },
      {
        // Settings
        text: 'STR_SETTINGS',
        to: '#',
      },
    ],
  },
  {
    heading: 'STR_ABOUT',
    items: [
      {
        // About SCAN Health
        text: 'STR_ABOUT_SCAN_HEALTH',
        to: '#',
      },

      {
        // Privacy Policy
        text: 'STR_PRIVACY_POLICY',
        to: '#',
      },
      {
        // Terms of Service
        text: 'STR_TERMS_OF_SERVICE',
        to: '#',
      },

      {
        // Delete your account
        text: 'STR_DELETE_YOUR_ACCOUNT',
        to: '#',
      },
    ],
  },
  {
    items: [
      {
        to: ROUTE_PATHS.SIGN_OUT,
        text: 'STR_LOG_OUT',
        linkProps: {
          color: 'onSurface.text.critical',
        },
        color: '$onSurfaceTextCritical',
      },
    ],
  },
];
