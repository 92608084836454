import { useState, useEffect } from 'react';
import { LOCAL_STORAGE_KEYS } from 'common/constants';
import { useGetUserProfile } from '@leagueplatform/web-common';

export const useOnboarding = () => {
  const { data: userProfileData, isLoading: userProfileDataIsLoading } =
    useGetUserProfile();
  const userAcceptedTermsAndConditions =
    !!userProfileData?.userProfile?.memberTermsAndConditions?.dateAccepted;
  const [featureHighlightsCompleted, setFeatureHighlightsCompleted] =
    useState<boolean>(
      !!window.localStorage.getItem(
        LOCAL_STORAGE_KEYS.FEATURE_HIGHLIGHTS_COMPLETED,
      ),
    );

  const onFeatureHighlightsComplete = () => {
    setFeatureHighlightsCompleted(true);
    window.localStorage.setItem(
      LOCAL_STORAGE_KEYS.FEATURE_HIGHLIGHTS_COMPLETED,
      'true',
    );
  };

  useEffect(() => {
    if (userAcceptedTermsAndConditions && !featureHighlightsCompleted) {
      window.localStorage.setItem(
        LOCAL_STORAGE_KEYS.FEATURE_HIGHLIGHTS_COMPLETED,
        'true',
      );
    }
  }, [userAcceptedTermsAndConditions, featureHighlightsCompleted]);

  return {
    showTermsAndConditions:
      featureHighlightsCompleted && !userAcceptedTermsAndConditions,
    showFeatureHighlights:
      !featureHighlightsCompleted && !userAcceptedTermsAndConditions,
    onFeatureHighlightsComplete,
    isLoading: userProfileDataIsLoading,
  };
};
