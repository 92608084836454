import { useContext } from 'react';
import type { ActorRefFrom } from 'xstate';
import { useSelector as useSelectorBase } from '@xstate/react';
import type { PBPChangeMachine } from '../state-machine/pbp-change-machine';
import { ActorRefContext } from '../actor-ref-context';

type Selector<T> = Parameters<
  typeof useSelectorBase<ActorRefFrom<PBPChangeMachine>, T>
>[1];

/**
 * Allows components to listen to specific parts of the state machine's current
 * state and/or context, to limit re-renders to the minimum necessary.
 */
export const useSelector = <T>(selector: Selector<T>) => {
  const actorRef = useContext(ActorRefContext);

  if (!actorRef) {
    throw new Error(
      'cannot call `useSelector` hook ooutside the context of the PBPChange page component!',
    );
  }

  return useSelectorBase(actorRef, selector);
};
