import React, { useEffect } from 'react';
import { useActorRef } from '@xstate/react';
import { pbpChangeMachine } from './state-machine/pbp-change-machine';
import { ActorRefContext } from './actor-ref-context';
import { StateViewer } from './components/state-viewer';
import { View } from './components/view';

// TODO: get from actual source of truth!
const USER_ID = '<string>';

export const PBPChange = () => {
  const actorRef = useActorRef(pbpChangeMachine, {
    input: { userId: USER_ID },
  });

  useEffect(() => {
    const unsubscribe = actorRef.subscribe((foo) => {
      console.log(foo);
    });

    return () => {
      unsubscribe.unsubscribe();
    };
  }, [actorRef]);

  return (
    <ActorRefContext.Provider value={actorRef}>
      <View />

      {/* TODO remove this, it's only for debugging */}
      <StateViewer />
    </ActorRefContext.Provider>
  );
};
