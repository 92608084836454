import createClient, { type Middleware } from 'openapi-fetch';
import type { paths } from 'pages/pbp-change/gen/pbp-change'; // generated by openapi-typescript
import { PBP_CHANGE_API_BASE_URL } from './constants';

// TODO: implement actual function!
const getAuthToken = async () =>
  Promise.resolve('<YOUR POSTMAN API KEY GOES HERE');

const middleware: Middleware = {
  async onRequest({ request }) {
    const token = await getAuthToken();
    // TODO: implement actual auth headers!
    request.headers.set('x-api-key', token);
  },
};

/**
 * A fully-typed fetch wrapper with which to make calls to SCAN's PBP Change API.
 */
const client = createClient<paths>({ baseUrl: PBP_CHANGE_API_BASE_URL });
client.use(middleware);

export { client };
