import React from 'react';
import { UserDropdownComponent } from '@leagueplatform/web-common-components';
import {
  EVENT_NAME,
  PRODUCT_AREA,
  SCREEN_NAMES,
} from '@leagueplatform/analytics';
import { useUserDropdownComponent } from 'hooks/use-user-dropdown/use-user-dropdown.hook';

export const UserDropdown = React.memo(() => {
  const { data } = useUserDropdownComponent();
  const userDropdownAnalytics = {
    name: EVENT_NAME.NAVIGATION_BAR_CLICKED,
    props: {
      product_area: PRODUCT_AREA.HOME_SCREEN,
      screen_name: SCREEN_NAMES.HOME_SCREEN,
      detail: 'drop down menu',
    },
  };

  return (
    <UserDropdownComponent
      label={data.userFirstName.toUpperCase()}
      dropdownItemsConfig={data.menuItemsConfig.map(
        (menuItems) => menuItems.items,
      )}
      userBarAnalyticsProps={userDropdownAnalytics}
      userBarProps={{
        buttonProps: {
          css: {
            backgroundColor: '$surfaceCardSecondary',
          },
        },
      }}
    />
  );
});
