import { lazy } from 'react';

export const LandingPage = lazy(() => import('pages/landing.page'));

export const Home = lazy(() => import('pages/home/home.page'));

export const PersonalInfo = lazy(
  () => import('pages/personal-info/personal-info.page'),
);

export const CareCollectionsRoutes = lazy(() =>
  import('@leagueplatform/care-collections').then((module) => ({
    default: module.CareCollectionsRoutes,
  })),
);
export const CareDiscoveryRoutes = lazy(() =>
  import('@leagueplatform/care-discovery').then((module) => ({
    default: module.CareDiscoveryRoutes,
  })),
);

export const WalletRoutes = lazy(() =>
  import('@leagueplatform/wallet').then((module) => ({
    default: module.WalletRoutes,
  })),
);

export const AboutMeRoutes = lazy(() =>
  import('@leagueplatform/about-me').then((module) => ({
    default: module.AboutMeRoutes,
  })),
);

export const OnboardingRoutesWithProvider = lazy(() =>
  import('@leagueplatform/onboarding').then((module) => ({
    default: module.OnboardingRoutesWithProvider,
  })),
);
