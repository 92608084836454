import { type PropsWithChildren } from 'react';
import { LoadingIndicator } from '@leagueplatform/web-common-components';
import OnboardingRoutesWithProvider from '@leagueplatform/onboarding';
import { useOnboarding } from 'hooks/use-onboarding/use-onboarding.hook';
import { BasePage } from 'components/base-page.component';
import { TENANT_ID } from 'common/constants';

export const PrivateRoutesContainer = ({ children }: PropsWithChildren) => {
  const { showFeatureHighlights, isLoading, onFeatureHighlightsComplete } =
    useOnboarding();

  if (isLoading) return <LoadingIndicator />;

  if (showFeatureHighlights)
    return (
      <OnboardingRoutesWithProvider
        contentfulId={import.meta.env.VITE_ONBOARDING_CONTENTFUL_ID}
        tenantId={TENANT_ID}
        onOnboardingComplete={onFeatureHighlightsComplete}
      />
    );

  return <BasePage>{children}</BasePage>;
};
