import { assign, setup } from 'xstate';
import type { Context, Event } from './pbp-change-machine.types';
import { startSession, fetchEnrollmentPeriods } from './actors';

export const pbpChangeMachine = setup({
  types: {
    input: {} as {
      // Required for starting enrollment sessions.
      userId: string;
      // When returning from the link-out with an ongoing enrollment ID
      enrollmentId?: string;
    },
    context: {} as Context,
    events: {} as Event,
  },
  actors: {
    startSession,
    fetchEnrollmentPeriods,
  },
}).createMachine({
  context: ({ input }) => ({
    userId: input.userId,
    enrollmentId: input.enrollmentId || '',
  }),
  initial: 'initializing',
  states: {
    /**
     * Before the user can begin the flow by answering eligibility questions,
     * we must start a session (if one is not started yet) and retrieve
     * all applicable enrollment periods. This is "initialization".
     */
    initializing: {
      initial: 'checkingForEnrollmentId',
      states: {
        /**
         * Check whether we need to start a new session (we only do if the machine
         * was not provided an ongoing enrollment ID when started).
         */
        checkingForEnrollmentId: {
          always: [
            {
              /**
               * If we have an enrollment ID given when the machine was started,
               * we know we need to go directly to "your care team" becasue the
               * user will be continuing where they left off.
               */
              guard: ({ context }) => !!context.enrollmentId,
              target: '#yourCareTeam',
            },
            /**
             * Otherwise, let's start a new session.
             */
            'startingSession',
          ],
        },
        startingSession: {
          invoke: {
            src: 'startSession',
            input: ({ context }) => ({ userId: context.userId }),
            onDone: {
              target: 'gettingEnrollmentPeriods',
              actions: assign({ enrollmentId: ({ event }) => event.output }),
            },
            onError: {
              target: '#error',
              actions: assign({ error: ({ event }) => event.error }),
            },
          },
        },
        gettingEnrollmentPeriods: {
          invoke: {
            src: 'fetchEnrollmentPeriods',
            onDone: {
              target: 'ready',
              actions: assign({
                allEnrollmentPeriods: ({ event }) => event.output,
              }),
            },
            onError: {
              target: '#error',
              actions: assign({ error: ({ event }) => event.error }),
            },
          },
        },
        ready: {
          type: 'final',
        },
      },
      onDone: {
        /**
         * By moving to the `ready` sub-state we signal that we are done with
         * initializaation. Proceed to asking eligibility questions.
         */
        target: 'eligibilityQuestions',
      },
    },
    eligibilityQuestions: {
      /**
       * As soon as we enter, if only one enrollment period was provided
       * by the API we can immediately place it in the context's `enrollmentPeriod`.
       */
      entry: assign({
        enrollmentPeriod: ({ context }) =>
          context.allEnrollmentPeriods!.length === 1
            ? context.allEnrollmentPeriods![0]
            : undefined,
      }),
      on: {
        selectEnrollmentYear: {
          /**
           * No need for a state transition when the user selects a year; we simply
           * switch between the enrollment periods based on their selection, which
           * results in different questions being displayed.
           */
          actions: assign({
            enrollmentPeriod: ({ context, event }) =>
              context.allEnrollmentPeriods!.find(
                ({ enrollmentYear }) => enrollmentYear === event.year,
              ),
          }),
        },
        submitEligibilityQuestionAnswers: {
          actions: assign({
            eligibilityQuestionAnswers: ({ event }) => event.answers,
          }),
          target: 'updateAddress',
        },
      },
    },
    updateAddress: {},
    yourCareTeam: {
      id: 'yourCareTeam',
    },
    error: {
      id: 'error',
      type: 'final',
    },
  },
});

export type PBPChangeMachine = typeof pbpChangeMachine;
