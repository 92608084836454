import { useUserName } from 'hooks/user-user-name/use-user-name.hook';
import { userDropdownConfig } from './user-dropdown-config';

export const useUserDropdownComponent = () => {
  const { userFirstName } = useUserName();
  const dropdownMenuItemProps = {
    userFirstName,
    menuItemsConfig: userDropdownConfig,
  };

  return { data: dropdownMenuItemProps };
};
