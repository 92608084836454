/* eslint-disable import/no-extraneous-dependencies */
import { Route, Switch } from '@leagueplatform/routing';
import { getModulePath, LEAGUE_MODULE_NAMES } from '@leagueplatform/core';
import { ROUTE_PATHS } from 'common/constants';
import {
  LandingPage,
  CareCollectionsRoutes,
  CareDiscoveryRoutes,
  AboutMeRoutes,
  Home,
  WalletRoutes,
  PersonalInfo,
} from 'components/lazy-routes.component';
import { AuthViewLogin, AuthViewLogout } from 'pages/auth/auth.pages';
import ErrorPage from 'pages/error-404.page';
import { PBPChange } from 'pages/pbp-change/pbp-change.page';
import { AuthorizedRoutes } from 'components/authorized-routes/authorized-routes.component';
import { PrivateRoutesContainer } from 'components/private-routes-container/private-routes-container';

function AppRoutes() {
  return (
    <Switch>
      <Route path={ROUTE_PATHS.SIGN_IN} component={AuthViewLogin} />
      <Route path={ROUTE_PATHS.SIGN_OUT} component={AuthViewLogout} />
      <Route path="/" exact component={LandingPage} />
      <AuthorizedRoutes>
        <PrivateRoutesContainer>
          <Switch>
            <Route path={ROUTE_PATHS.HOME} component={Home} exact key="home" />
            <Route
              path={ROUTE_PATHS.PERSONAL_INFO}
              component={PersonalInfo}
              exact
            />
            <Route
              path={getModulePath(LEAGUE_MODULE_NAMES.careCollections)}
              component={CareCollectionsRoutes}
            />
            <Route
              path={getModulePath(LEAGUE_MODULE_NAMES.careDiscovery)}
              component={CareDiscoveryRoutes}
            />
            <Route
              path={getModulePath(LEAGUE_MODULE_NAMES.wallet)}
              component={WalletRoutes}
            />
            <Route
              path={getModulePath(LEAGUE_MODULE_NAMES.aboutMe)}
              component={AboutMeRoutes}
            />
            <Route path="/pbp-change" component={PBPChange} />
            <Route component={ErrorPage} />
          </Switch>
        </PrivateRoutesContainer>
      </AuthorizedRoutes>
    </Switch>
  );
}

export default AppRoutes;
